import {
  ButtonGroup,
  Col,
  Container,
  Dropdown,
  Nav,
  NavDropdown,
  Row,
} from "react-bootstrap"

import LanguageSwitcher from "./languageSwitcher"
import { Link } from "gatsby-plugin-i18next"
import Navbar from "react-bootstrap/Navbar"
import PropTypes from "prop-types"
import React from "react"
import { translate } from "react-i18next"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"

const Header = ({ t, i18n: { language }, pageContext, ...props }) => {
  const [stickyTop, setStickyTop] = React.useState(false)
  const logo = React.useRef()
  useScrollPosition(
    ({ currPos }) => {
      const isShow = currPos.y <= -128
      if (isShow !== stickyTop) setStickyTop(isShow)
    },
    [stickyTop]
  )

  return (
    <Navbar bg="white" expand="lg" sticky={stickyTop && "top"} className="p-0">
      <div className="navbar-wrapper nav-fill">
        <Container>
          <Row
            className={
              stickyTop
                ? `justify-content-center logo-big logo-hide`
                : `justify-content-center logo-big `
            }
          >
            <img src={props.logo} alt="logo" className="logo" ref={logo} />
            <LanguageSwitcher />
          </Row>
        </Container>
        <Navbar.Toggle aria-controls="main-nav" />
        <Navbar.Collapse id="main-nav" className="bg-white">
          <Container>
            <Row>
              <Col>
                <Nav fill>
                  {stickyTop && (
                    <Nav.Item>
                      <img
                        src={
                          language === "en"
                            ? "/mnca-small-eng.png"
                            : "/mnca-small-mng.png"
                        }
                        alt="logo"
                        className={
                          stickyTop
                            ? `logo logo-small`
                            : `logo logo-small logo-hide`
                        }
                        ref={logo}
                      />
                    </Nav.Item>
                  )}
                  <Link
                    className="nav-link nav-item h6"
                    to="/"
                    activeClassName="active"
                  >
                    {t("home")}
                  </Link>
                  <NavDropdown
                    title={t("founders")}
                    id="basic-nav-dropdown"
                    className={`h6 ${pageContext &&
                      pageContext.originalPath &&
                      [
                        "/associations/",
                        "/mctic-home/",
                        "/mctic-about/",
                        "/mctic-services/",
                      ].includes(pageContext.originalPath) &&
                      "active"}`}
                  >
                    <Link
                      className="dropdown-item nav-link h6"
                      activeClassName="active"
                      to="/associations"
                    >
                      {t("associations")}
                    </Link>
                    <NavDropdown.Divider />
                    <Dropdown as={ButtonGroup} alignRight>
                      <Link
                        className="dropdown-item nav-link h6"
                        to="/mctic-home"
                        activeClassName="active"
                      >
                        {t("mctic")}
                      </Link>
                      <Dropdown.Toggle
                        split
                        variant="outline-secondary"
                        id="dropdown-split-basic"
                      />
                      <Dropdown.Menu>
                        <Link
                          className="dropdown-item nav-link h6"
                          to="/mctic-about"
                          activeClassName="active"
                        >
                          {t("about_us")}
                        </Link>
                        <NavDropdown.Divider />

                        <Link
                          className="dropdown-item nav-link h6"
                          to="/mctic-services"
                          activeClassName="active"
                        >
                          {t("services")}
                        </Link>
                      </Dropdown.Menu>
                    </Dropdown>
                  </NavDropdown>
                  <Link
                    className="nav-link nav-item h6"
                    activeClassName="active"
                    to="/cooperative"
                  >
                    {t("what_is_coop")}
                  </Link>
                  <Link
                    className="nav-link nav-item h6"
                    activeClassName="active"
                    to="/plan"
                  >
                    {t("strategy")}
                  </Link>
                  <Link
                    className="nav-link nav-item h6"
                    activeClassName="active"
                    to="/contact"
                  >
                    {t("holbogdoh")}
                  </Link>
                </Nav>
              </Col>
            </Row>
          </Container>
        </Navbar.Collapse>
      </div>
    </Navbar>
  )
}
Header.propTypes = {
  siteTitle: PropTypes.string,
  t: PropTypes.func,
  i18n: PropTypes.object,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default translate()(Header)
