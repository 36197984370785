import { Button, ButtonGroup } from "react-bootstrap"

import { Language } from "gatsby-plugin-i18next"
import React from "react"

const Switcher = ({ changeLng, lng, availableLngs }) => (
  <ButtonGroup aria-label="Language switcher" className="language_switcher">
    {availableLngs.map(value => (
      <Button
        key={value}
        className={value === lng ? "active" : undefined}
        variant="main"
        onClick={() => changeLng(value)}
      >
        {value === "mn" ? "MN" : value.toUpperCase()}
      </Button>
    ))}
  </ButtonGroup>
)

const LanguageSwitcher = () => (
  <Language>{lngProps => <Switcher {...lngProps} />}</Language>
)

LanguageSwitcher.displayName = "LanguageSwitcher"
export default LanguageSwitcher
