/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { Col, Row } from "react-bootstrap"
import { graphql, useStaticQuery } from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Header from "./header"
import PropTypes from "prop-types"
import React from "react"
import { faCaretUp } from "@fortawesome/free-solid-svg-icons"
import { translate } from "react-i18next"

const useScrollToTop = (initialScrollState = false) => {
  const [scrollToTop, setScrollToTop] = React.useState(initialScrollState)

  React.useEffect(() => {
    if (scrollToTop) {
      setScrollToTop(false)
      try {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        })
      } catch (error) {
        window.scrollTo(0, 0)
      }
    }
  }, [scrollToTop, setScrollToTop])

  return setScrollToTop
}

const mctic_routes = ["/mctic-home/", "/mctic-about/", "/mctic-services/"]
const mnca_logos = {
  en: "/mnca-eng.png",
  mn: "/mnca-mng.png",
}

const mnca_logos_white = {
  en: "/mnca-eng-white.png",
  mn: "/mnca-mng-white.png",
}

const mctic_logos = {
  en: "/mctic-eng.png", //Logo source
  mn: "/mctic-mng.png", //logo source
}

const mctic_logos_white = {
  en: "/mctic-eng-white.png", //Logo source
  mn: "/mctic-mng-white.png", //logo source
}

const Layout = ({ children, t, i18n: { language }, pageContext }) => {
  const setScrollTop = useScrollToTop()
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  function Logo() {
    return pageContext && mctic_routes.includes(pageContext.originalPath)
      ? mctic_logos[language]
      : mnca_logos[language]
  }

  function footerLogo() {
    if (pageContext && mctic_routes.includes(pageContext.originalPath)) {
      return mctic_logos_white[language]
    }
    return mnca_logos_white[language]
  }

  return (
    <>
      <Header
        siteTitle={data.site.siteMetadata.title}
        pageContext={pageContext}
        logo={Logo()}
      />
      {children}
      <footer className="section bg-dark-blue m-0">
        <div className="container">
          <Row className="align-items-center">
            <Col className="col-auto mb-3 mb-md-0">
              <a href="index.html">
                <img src={footerLogo()} alt="Logo" className="logo" />
              </a>
            </Col>
          </Row>
          <Row className="justify-content-between align-items-center mt-3 mt-md-4">
            <div className="col-lg-auto col-12 order-lg-2">
              {/* <ul className="list-social-links d-flex mb-lg-0">
                <li>
                  <a href="#">
                    <i className="socicon-facebook"></i>
                  </a>
                </li>
              </ul> */}
              <button
                id="back_to_top"
                className="back-to-top icon-rounded bg-blue shadow sticky-viewport-bottom position-fixed"
                onClick={() => setScrollTop(true)}
              >
                {/* <span className="button_icon"> */}
                <FontAwesomeIcon icon={faCaretUp} />
                {/* </span> */}
              </button>
            </div>
            <div className="col order-lg-1">
              <span className="text-small opacity-50">
                &copy; 2019 {t("location")}
              </span>
            </div>
          </Row>
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default translate()(Layout)
